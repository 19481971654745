class Header {
  _parentElement;
  _selector = {
    menuOpen: '[data-menu-open]',
    menuClose: '[data-menu-close]',
    menu: '[data-menu]',
  }
  _headerHeight;
  _lastScrollTop = 0;

  /**
   * @param {Element} el
   */
  init(el) {
    if (!el) return;
    this._parentElement = el;
    this._addHandlerMenu(this._selector.menuOpen);
    this._initSlimHeader();
  }

  _slimHeader(entries) {
    const [entry] = entries;
    const active = !entry.isIntersecting;

    active
      ? this._parentElement.setAttribute('data-state', 'slim')
      : this._parentElement.setAttribute('data-state', '');
  }

  _initSlimHeader() {
    this._headerHeight = this._parentElement.getBoundingClientRect().height;
    const header = this._parentElement.closest('#header');
    const headerObserver = new IntersectionObserver(this._slimHeader.bind(this), {
      root: null,
      threshold: 0,
      rootMargin: `${this._headerHeight * 2}px`
    });

    headerObserver.observe(header);
  }

  _toggleMenu() {
    const menu = this._parentElement.querySelector(this._selector.menu);
    const close = this._parentElement.querySelector(this._selector.menuClose);
    if (!menu) return;
    const {state} = menu.dataset;

    if (state !== 'open') {
      menu.setAttribute('data-state', 'open');
      close.focus();
      this._addHandlerMenu(this._selector.menuClose);
    }

    if (state === 'open') {
      menu.setAttribute('data-state', '');
    }
  }

  /**
   * @param {String} selector
   */
  _addHandlerMenu(selector = this._selector.menuOpen) {
    this._parentElement.addEventListener('click', function(e) {
      const btn = e.target.closest(selector);
      if (!btn) return;
      this._parentElement = btn.closest('[data-module="header"]');
      this._toggleMenu();
    }.bind(this));
  }
}

export default new Header();
