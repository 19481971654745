class Accordion {
  _el;
  _trigger;
  _isOpen;
  _panel;

  init($el) {
    this._el = $el;
    this.bindTrigger();
  }

  bindTrigger() {
    this._el.addEventListener('click', function(e) {
      this._trigger = e.target.closest('[data-accordion-trigger]');
      if (!this._trigger) return;
      this._isOpen = this._trigger.getAttribute('aria-expanded') === 'true';
      this.toggle(!this._isOpen);
    }.bind(this));
  }

  toggle(isOpen) {
    this._panel = document.getElementById(this._trigger.getAttribute('aria-controls'));
    if (isOpen === this._isOpen) return;

    this._isOpen = isOpen;
    this._trigger.setAttribute('aria-expanded', `${isOpen}`);

    if (isOpen) {
      this._panel.removeAttribute('hidden');
    } else {
      this._panel.setAttribute('hidden', '');
    }
  }
}

export default new Accordion();
