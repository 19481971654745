class Filter {
  _parentElement;
  _tabs;
  _panels;

  init(el) {
    if (!el) return;
    this._parentElement = el;
    this._tabs = el.querySelectorAll('[data-filter-item] span');
    this._panels = el.querySelectorAll('[data-filter-panel]');
    this._bindFilter();
  }

  _bindFilter() {
    this._parentElement.addEventListener('click', function(e) {
      const $tab = e.target.closest('[data-filter-item]');
      if ($tab) {
        this._setTabActive($tab);
        this._togglePanel($tab);
      }
    }.bind(this));
  }

  /**
   * @param {Object} $tab
   */
  _togglePanel($tab) {
    const target = $tab.dataset.filterItem;
    !target && this._resetPanels();
    target && this._showPanels(target);
  }

  /**
   * @param {Object} $tab
   */
  _setTabActive($tab) {
    this._tabs.forEach(e => e.dataset.state = 'inactive');
    $tab.querySelector('span').dataset.state = 'active';
  }

  /**
   * @param {String} target
   */
  _showPanels(target) {
    this._panels.forEach(e => e.style.display = 'none');
    const $panels = this._parentElement.querySelectorAll(`[data-filter-panel*="${target}"]`);
    $panels.forEach(e => e.style.display = 'block');
  }

  _resetPanels() {
    this._panels.forEach(e => e.style.display = 'block');
  }
}

export default new Filter();
