import Header from './modules/Header';
import Accordion from './modules/Accordion';
import Filter from './modules/Filter';

/**
 * Get all modules from DOM with
 * data-module attribute
 * @param {String} selector
 * @returns {NodesList|Null}
 */
const moduleController = function(selector) {
  if (!selector) return;
  const module = document.querySelectorAll(`[data-module="${selector}"]`);
  return module.length > 0 ? module : null;
}

/**
 * Call init() function of module
 * @param {String} selector
 * @param {Object} module
 */
const moduleInit = function(selector, module) {
  if (!selector || !module) return;
  const item = moduleController(selector);
  if (!item) return;

  item.forEach(el => module.init(el));
}

const init = function() {
  moduleInit('header', Header);
  moduleInit('accordion', Accordion);
  moduleInit('filter', Filter);
}

init();
